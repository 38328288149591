import React from 'react'
import { graphql } from 'gatsby';
import { Helmet } from "react-helmet";
import Masonry from 'react-masonry-css'

import MyMarquee from '../components/Marquee'
import Cursor from '../components/Cursor';
import GalleryTile from '../components/GalleryTile';
import GalleryFilter from '../components/GalleryFilter';
import HeaderAndMenu from '../components/HeaderAndMenu';
import Footer from '../components/Footer';

import styled from 'styled-components';
import {device} from '../styles/Breakpoints'


function EmptyState(props) {
  // console.log('empty props', props)
  if (props.filteredGalleries.length == 0) {
    const filters = props.filterStrings.join(' + ')
    return <div className="no-galleries">No galleries matching {filters}</div>
  }
  else {
    return <></>
  }
}



class GalleriesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        availableRoles: [],
        filterStrings: [],
        filteredGalleries: [],
        allGalleries: []
    };
  }

  componentDidMount() {
    this.filterGalleries();
    this.getRoles();
    this.checkQueryParams();
  }

  checkQueryParams = () => {
    const url = new URL(location.href);
    const filterParams = url.searchParams.get("filter")
    // console.log('params', filterParams)
    if (filterParams !== null) {
      this.state.filterStrings.push(filterParams);
      this.filterGalleries();
      window.history.replaceState({}, document.title, "/" + "galleries");
    }
  }

  getRoles = () => {
    const availableRolesArray = []
    this.props.data.galleryData.nodes.forEach(gallery => {
      for (const role of gallery.role) {
        if (!availableRolesArray.includes(role.name)) {
          availableRolesArray.push(role.name)
        }
        
      }
      
    })
    this.setState({availableRoles: availableRolesArray})
  }
 
  setFilterString = (string) => {
    
    if (this.state.filterStrings.includes(string)) {
      // If the filter/role clicked is already active
      if (this.state.filterStrings.length == 1) {
        // if it's the only one, set back to unfiltered
        const emptyArray = [];
        this.setState({filterStrings: emptyArray}, function() {this.filterGalleries()});
      }
      else {
        // Otherwise if there are other active filters, just remove the one thats been clicked
        const strippedArray = this.state.filterStrings.splice(this.state.filterStrings.indexOf(string) -1, 1)
        this.setState({filterStrings: strippedArray}, function() {this.filterGalleries()} );
      }
      
    }
    else {
      // If the clicked filter/role isnt already active, add it to the array
      const stateArray = [...this.state.filterStrings]
      stateArray.push(string)
      this.setState({filterStrings: stateArray}, function() {this.filterGalleries()});
    }
  }

  arrayContainsArray = (superset, subset) => {
    if (0 === subset.length) {
      return false;
    }
    return subset.every(function (value) {
      return (superset.indexOf(value) >= 0);
    });
  }

  doGalleryRolesMatchFilters = (gallery) => {
    // console.group(gallery.name);
    // let trueCount = 0;
    if (gallery.role.length) {
      const thisGalleriesRoles = [];
      gallery.role.forEach(role => thisGalleriesRoles.push(role.name));
      // console.log(gallery.name, ' - ', thisGalleriesRoles)
      return this.state.filterStrings.every( val =>  thisGalleriesRoles.includes(val))

      // is - filterstrings a subset of gellery.roles

      
      // gallery.role.forEach(role => {
      //   console.log(role.name)
      //   this.state.filterStrings.forEach(filterString => {
      //     if (filterString == role.name) {
      //       console.log('role matches filterstring')
      //       trueCount++
      //     }
          
      //   })
      // })
      // console.groupEnd()
      // return trueCount > 0 ? true : false
    }




      // for each role this gallery has
      // for (let i = 0; i < gallery.role.length; i++) {
      //   // check each filter string
      //   for (let k = 0; k < this.state.filterStrings.length; k++) {
      //     // if role matches filterstring
      //     if (gallery.role[i].name == this.state.filterStrings[k]) {
      //       return true
      //     }
      //     else {
      //       return false
      //     }

      //   }
        
      // }
  }


  filterGalleries = () => {
    if (this.state.filterStrings.length) {
      const newArray = []
      this.props.data.galleryData.nodes.forEach(gallery => {
        // console.log('fiterstrings is subset of roleS: ', ' ', gallery.name, ' ', this.doGalleryRolesMatchFilters(gallery))
        // filter if roles strictly match filters 
        if (this.doGalleryRolesMatchFilters(gallery)) {

            newArray.push(gallery)

        }
        
        // filter if role is found within filters
        // gallery.role.forEach(role => {
        //   if (this.state.filterStrings.includes(role.name)) {
        //     newArray.push(gallery)
        //   }
        // })
      })
      this.setState({filteredGalleries: newArray})
    }
    else {
      // console.log('no active filter')
      this.setState({filteredGalleries: this.props.data.galleryData.nodes})
    }
  }

  emptyFilters = () => {
    this.setState({filterStrings: []}, function() {this.filterGalleries()} );
    // this.setState({filterStrings: []})
    // this.filterGalleries();
  }


  render() {

    const GalleryPageStyles = styled.main`
    min-height: 100vh;
    padding-left: 12vw;
    padding-right: 12vw;
    .no-galleries {
      text-align: center;
      width: 100%;
      margin: 24px 0;
    }

    .masonry-grid__column {
      padding-left: 10vw; /* gutter size */
    }
    .masonry-grid {
      margin-left: -10vw; /* gutter size offset */
    }
    .masonry-grid__column > div { 
      margin-bottom: 10vw;
    }

    @media ${device.$small} {
      padding-left: 12px;
      padding-right: 12px;
      .masonry-grid__column {
        padding-left: 12px; /* gutter size */
      }
      .masonry-grid {
        margin-left: -12px; /* gutter size offset */
      }
      .masonry-grid__column > div { 
        margin-bottom: 12px;
      }
    }
  `

    const data = this.props.data
    // console.log('GALLERIES PAGE DATA', this.props.data)

    const roleData = data.allSanityRole.nodes

    
    const galleryThumbs = []

    this.state.filteredGalleries.forEach(gallery => {
      galleryThumbs.push(
        <GalleryTile galleryProps={gallery} key={gallery.name} />)
    })

    
    return (
      <>
      <Helmet>
        <title>Galleries | Imogen Wilson</title>
      </Helmet>
      <Cursor 
        staticText={data.siteSettings.nodes[0].cursor_static}
        staticTextColor={data.siteSettings.nodes[0].cursor_static_color.hex}
        cursorSparkle={data.siteSettings.nodes[0].cursor_sparkle}         
      />
      <HeaderAndMenu siteSettings={data.siteSettings.nodes[0]} roleData={roleData} page={'gallery'}/>
      <MyMarquee color={data.siteSettings.nodes[0].marquee_colour?.rgb} content={data.siteSettings.nodes[0].marquee_text}/>
      <GalleryPageStyles className="container">
        <GalleryFilter 
            availableRoles={this.state.availableRoles} 
            allRoles={data.allSanityRole.nodes} 
            setFilterString={this.setFilterString} 
            activeRoles={this.state.filterStrings} 
            emptyFilters={this.emptyFilters}
            siteSettings={data.siteSettings.nodes[0]}
        />
        
            <Masonry
                breakpointCols={{ default: 3, 768: 2, 375: 2 }}
                className="masonry-grid"
                columnClassName="masonry-grid__column">
            { galleryThumbs }
            </Masonry>
            <EmptyState filteredGalleries={this.state.filteredGalleries} filterStrings={this.state.filterStrings}/>
            
            
        </GalleryPageStyles>
        <Footer siteSettings={data.siteSettings.nodes[0]} />
        </>
    )
  }
}
export default GalleriesPage

export const query = graphql`
query {
    galleryData: allSanityGallery {
        nodes {
          name
          role {
            name
          }
          images {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
          cover_image {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          enable_cover_video
          cover_video {
            mux_video {
              asset {
                playbackId
              }
            }
            video_file {
              asset {
                url
                mimeType
              }
            }
          }
          cover_video_vimeo
          slug {
            current
          }
          vimeo_url
        }
    }
    siteSettings: allSanitySiteSettings {
        nodes {
          logo {
            asset {
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid_noBase64
              }
              extension
              url
            }
          }
          menu_colour {
            hex
          }
          menu_text_colour {
            hex
          }
          menu_emoji_one
          menu_emoji_one_url
          menu_emoji_two
          menu_emoji_two_url
          menu_emoji_three
          menu_emoji_three_url
          marquee_colour {
            rgb {
              a
              b
              g
              r
            }
          }
          marquee_text
          cursor_static
          cursor_sparkle
          cursor_static_color {
            hex
          }
          mimi_casting_url
          instagram_url
          shop_url
          vimeo_url
          footer_mailto
          hover_gradient_one {
            hex
          }
          hover_gradient_two {
            hex
          }
          hover_gradient_three {
            hex
          }
        }
    }
    allSanityRole {
      nodes {
        name
        order
      }
    }
}
  
`