import React from 'react'
import { Link } from 'gatsby';

import styled from 'styled-components';
import {device} from '../styles/Breakpoints'

const FilterStyles = styled.section`
    padding: 170px 24px 48px;
    min-height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 26px;
    font-weight: 900;
    text-transform: uppercase;
    @media ${device.$small} {
        padding-top: calc(var(--mobile-header-height) + var(--gutter));
        padding-bottom: 48px;
    }
    .role-filter {
        cursor: pointer;
        position: relative;
        &.active {
            span.role {
            background: -webkit-linear-gradient(1turn, ${props => props.siteSettings.hover_gradient_one.hex}, ${props => props.siteSettings.hover_gradient_two.hex}, ${props => props.siteSettings.hover_gradient_three.hex});siteSettings={data.siteSettings.nodes[0]}
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            }
            &:hover {
                font-family: var(--body-font);
                font-size: 26px;
                .hover-gif {
                    opacity: 1;
                }
            }
        }
        &.inactive {
            &:hover {
                span.role {
                    background: -webkit-linear-gradient(1turn, ${props => props.siteSettings.hover_gradient_one.hex}, ${props => props.siteSettings.hover_gradient_two.hex}, ${props => props.siteSettings.hover_gradient_three.hex});
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;  
                }
                 
                /* font-family: var(--filter-hover-font-family);
                font-size: 29px;
                color: #AD458A;
                margin-right: 4px; */
                .hover-gif {
                    opacity: 1;
                }
                /* .hover-dropshadow {
                    opacity: 1;
                }    */
            }
        }
        
        span.role {
            display: block;
            z-index: 3;
            position: relative;
        }
        /* .hover-dropshadow {
            position: absolute;
            left: -4px;
            top: 1px;
            font-family: var(--filter-hover-font-family);
            font-size: 29px;
            color: #EF8888;
            opacity: 0;
            z-index: 2
        } */
        .hover-gif {
            position: absolute;
            width: initial;
            height: 85px;
            max-width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: .2s all ease-in-out;
            z-index: 1;
        }
    }
`



export default function GalleryFilter({ availableRoles, allRoles, setFilterString, activeRoles, emptyFilters, siteSettings}) {
    const filters = []
    
    // console.log('ALLROLE', allRoles)

    availableRoles.forEach((role, index, array) => {
        const className = activeRoles.includes(role) ? 'role-filter active' : 'role-filter inactive';
        const roleMatch = allRoles.find(findRole => {
            return findRole.name == role
        })
    filters.push(
        <div onClick={() => setFilterString(role)} key={role} order={roleMatch.order} className="d-flex">
            <div className={className}>
                <span className="role">{role}</span>
                <img className="hover-gif" src="https://media.giphy.com/media/8FryAist7dyO6qAKMM/giphy.gif" />
            </div>
        </div>)
    });

    // console.log('filtas', filters)

    const sortedFilters = filters.sort((a,b) => {
       return a.props.order - b.props.order
    })

    const newArray = []

    for (let i = 0; i < sortedFilters.length; i++) {
        // console.log('FILT', sortedFilters[i])
        if (i + 1 == sortedFilters.length) {
            newArray.push(<React.Fragment key={i}>{sortedFilters[i]}</React.Fragment>)
        }
        else {
            newArray.push(<React.Fragment key={i}>{sortedFilters[i]}<span>&nbsp;/&nbsp;</span></React.Fragment>)
        }
        
        
    }

    const allClassName = activeRoles.length ? 'role-filter inactive' : 'role-filter active';



    return (
        <FilterStyles siteSettings={siteSettings}>
            <div onClick={() => emptyFilters()} className={allClassName}>
                <span className="role">ALL&nbsp;</span>
                <img className="hover-gif" src="https://media.giphy.com/media/8FryAist7dyO6qAKMM/giphy.gif" />
            </div>/&nbsp;
            { newArray }
        </FilterStyles>
    )
}